import React from "react";
import axois from "axios";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import srLocale from "@fullcalendar/core/locales/sr";

export default function Events() {
    const [events, setEvents] = React.useState([]);
    React.useEffect(() => {
        axios.get("/api/eventData").then((res) => {
            setEvents(res.data);
        });
    }, []);

    function handleClick({ event }) {
        return (window.location.href = `/events/${event.id}`);
    }

    return (
        <FullCalendar
            defaultView="dayGridMonth"
            plugins={[dayGridPlugin, timeGridPlugin]}
            defaultView={"timeGridWeek"}
            header={{
                right: "today prev,next",
                center: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                left: "title",
            }}
            locale={srLocale}
            minTime={"12:00:00"}
            maxTime={"21:00:00"}
            height={700}
            events={events}
            eventClick={handleClick}
        />
    );
}
